<template>
    <div>
        <div class="login-dialog">
            <!-- 登录/注册 -->
            <div v-if="tabIndex == 0 || tabIndex == 1|| tabIndex == 4" class="flex_div">
                <div class="logo-box">
                    <img class="logo" src="@/assets/images/logo.png" />
                    <!-- <svg class="iconpark-icon-close" id="iconpark-icon-close" @click="closeDialog()">
                        <use href="#c-close"></use>
                    </svg> -->
                </div>
                <ul class="tab">
                    <li :class="{ active: tabIndex == 1 }" @click="tabIndex = 1">
                        {{$t(`loginDialogPage.type_phone_or_email`)}}
                    </li>
                    <li :class="{ active: tabIndex == 0 }" @click="tabIndex = 0">
                        {{$t(`loginDialogPage.type_psd`)}}
                    </li>
                    <li :class="{ active: tabIndex == 4 }" @click="tabIndex = 4" style="display:none">
                        {{$t(`loginDialogPage.type_email`)}}
                    </li>
                </ul>
                <!-- 谷歌登录 -->
                <div class="google_login" v-if="language!='zh_CN'">
                    <button @click="goolgeLogin">
                        <img src="@/assets/images/google.png" />
                        Continue with Google
                    </button>
                    <el-divider>OR</el-divider>
                </div>
                <!-- 密码登录 -->
                <div class="form-wrapper" id="password-login" v-show="tabIndex == 0">
                    <div class="form-item">
                        <label>{{$t(`loginDialogPage.account_number`)}}</label>
                        <div>
                            <input id="tel" class="input" v-model="accountNumber" :placeholder="$t(`loginDialogPage.phoneoremail_placeholder`)" @keydown.enter="pwdLogin" />
                        </div>
                    </div>
                    <div class="form-item">
                        <label>{{$t(`loginDialogPage.psd`)}}</label>
                        <div class="p-r login-password">
                            <input class="input" id="pwd" maxlength="15" :type="pwdPreview ? 'password' : 'text'" v-model="password" :placeholder="$t(`loginDialogPage.psd_placeholder`)" @keydown.enter="pwdLogin" />
                            <svg class="iconpark-icon" v-if="pwdPreview" @click="pwdPreview = false" id="preview-closed">
                                <use href="#preview-close"></use>
                            </svg>
                            <svg v-else class="iconpark-icon" @click="pwdPreview = true" id="preview-opend">
                                <use href="#preview-open"></use>
                            </svg>
                        </div>
                    </div>
                    <div class="forget-password">
                        <label class="remember-account cursor-pointer" for="remember-account">
                            <!-- <input type="checkbox" id="remember-account" />
                            <span>{{$t(`loginDialogPage.remember`)}}</span> -->
                        </label>
                        <span class="color-primary cursor-pointer" id="forget-password" @click="clickForget()">
                            {{$t(`loginDialogPage.forget_psd`)}}
                        </span>
                    </div>
                    <button class="login" id="loginBtn" @click="pwdLogin" @keydown.enter="pwdLogin" v-loading='loading' :disabled="loading">
                        {{$t(`loginDialogPage.login`)}}
                    </button>
                    <img v-if="language=='zh_CN'" class="wx_chat" src="@/assets/images/weichat.png" @click="checkWXLogin" />
                    <!-- <p class="form-desc">
                        没有账号？
                        <span @click="registerNow" class="color-primary cursor-pointer" id="register-now">
                            立即申请免费试用
                        </span>
                        </p> -->
                </div>
                <!-- 手机号/邮箱验证码登录 -->
                <div class="form-wrapper" id="sms-login" v-show="tabIndex == 1">
                    <div class="form-item">
                        <label>{{$t(`loginDialogPage.phoneoremail`)}}</label>
                        <div>
                            <input v-model="mobile" id="tel2" class="input" :placeholder="$t(`loginDialogPage.phoneoremail_placeholder`) " @keydown.enter="codeLogin" />
                        </div>
                    </div>
                    <div class="form-item">
                        <label>{{environment=='test'? $t(`loginDialogPage.inside_code`):$t(`loginDialogPage.code`)}}</label>
                        <div class="p-r">
                            <input id="validateCode" v-model="smsCode" class="input verification-code" type="text" :placeholder="$t(`loginDialogPage.code_placeholder`) " maxlength="6" @keydown.enter="codeLogin" />
                            <p class="send-code" v-if="environment!='test'">
                                <input type="button" @click="sentCode" :value="msgText" id="send-code" />
                            </p>
                        </div>
                    </div>
                    <p class="error-text" id="error-code" style="display: none">
                        <svg class="iconpark-icon-error">
                            <use href="#Frame"></use>
                        </svg>
                    </p>
                    <div style="height: 26px"></div>
                    <button class="login" id="loginBtn2" @click="codeLogin" @keydown.enter="codeLogin" v-loading='loading' :disabled="loading">
                        {{$t(`loginDialogPage.loginOrRegister`)}}
                    </button>
                    <p class="form-desc"> {{$t(`loginDialogPage.register_tips`)}}</p>
                    <img v-if="language=='zh_CN'" class="wx_chat" src="@/assets/images/weichat.png" @click="checkWXLogin" />

                </div>
                <!-- 邮箱登录 --暂时去掉 -->
                <div class="form-wrapper" id="sms-login" v-show="tabIndex == 4">
                    <div class="form-item">
                        <label>邮箱登录</label>
                        <div>
                            <input v-model="email" id="tel2" class="input" placeholder="请输入邮箱" />
                        </div>
                    </div>
                    <div class="form-item">
                        <label>验证码</label>
                        <div class="p-r">
                            <input id="validateCode" v-model="smsCode" class="input verification-code" type="text" placeholder="请输入验证码" maxlength="6" />
                            <p class="send-code">
                                <input type="button" @click="emailsentCode" :value="msgText" id="send-code" />
                            </p>
                        </div>
                    </div>
                    <p class="error-text" id="error-code" style="display: none">
                        <svg class="iconpark-icon-error">
                            <use href="#Frame"></use>
                        </svg>
                    </p>
                    <div style="height: 26px"></div>
                    <button class="login" id="loginBtn2" @click="emailLogin" @keydown.enter="emailLogin" v-loading='loading' :disabled="loading">
                        登录/注册
                    </button>
                    <p class="form-desc">未注册手机号验证后将自动登录</p>
                    <img class="wx_chat" src="@/assets/images/weichat.png" @click="checkWXLogin" />

                </div>
                <div class="agreement-policy">
                    <p>
                        {{$t(`loginDialogPage.confirmation_agreement.text1`)}}<span class="link" @click="goPolicy(1)"> {{$t(`loginDialogPage.confirmation_agreement.agreement1`)}}</span> {{$t(`loginDialogPage.confirmation_agreement.text2`)}}<span class="link" @click="goPolicy(2)">{{$t(`loginDialogPage.confirmation_agreement.agreement2`)}}</span>
                    </p>
                </div>
            </div>
            <!-- 微信登录 -->
            <div class="forget-password-wrapper" v-show="tabIndex == 5">
                <div class="logo-box">
                    <img class="logo" src="@/assets/images/logo.png" />
                    <!-- <svg class="iconpark-icon-close" id="iconpark-icon-close" @click="closeDialog()">
                        <use href="#c-close"></use>
                    </svg> -->
                </div>
                <div class="title_top">
                    <div class="top_div">
                        <img class="weichat" src="@/assets/images/weichat.png" />
                        <h4>微信登录</h4>
                    </div>
                </div>
                <div class="form-wrapper wx_login" id="password-login" v-loading="loadingLog">
                    <div @mouseleave="hideMask">
                        <img class="code_img" :src="getchatDetail.image_url" @mouseenter.stop="debouncedShowMask" />
                        <div class="mask" v-show="isMaskVisible">
                            <el-button type="success" round @click="refreshQrcode">刷新</el-button>
                            <!-- <button ></button> -->
                        </div>
                    </div>
                    <p>请使用手机扫码登录</p>
                    <el-button plain style="margin-top:20px" @click="checkPlan">切换其他登录方式</el-button>

                </div>

                <div class="agreement-policy">
                    <p>
                        登录即表示同意<span class="link" @click="goPolicy(1)">用户协议</span>和<span class="link" @click="goPolicy(2)">个人信息保护政策</span>
                        <!-- 登录即表示同意<a class="link"  href="#/UserAgreement">用户协议</a>和<a class="link"  href="#/PrivacyAgreement">个人信息保护政策</a> -->
                    </p>
                </div>
            </div>
            <!-- 绑定手机号 -->
            <div class="forget-password-wrapper" v-show="tabIndex == 6">
                <div class="logo-box">
                    <img class="logo" src="@/assets/images/logo.png" />
                </div>
                <div class="title_top">
                    <div class="login_success">
                        <i class="el-icon-success" style="color:#09CD97;font-size:30px"></i>
                        <h4>登录成功</h4>
                    </div>
                    <p class="cont_p">根据国家法律法规要求，绑定手机号获得更高级的安全保护。同时，您可以直接使用手机号进行多平台登录，方便快捷。</p>

                    <!-- <div class="top_div">
                        <h4 style="font-size:16px">绑定手机号</h4>
                    </div> -->
                </div>
                <div class="form-wrapper" id="sms-login">
                    <div class="form-item">
                        <!-- <label>手机号</label> -->
                        <div>
                            <input v-model="mobile" id="tel2" class="input" maxlength="11" placeholder="请输入手机号" />
                        </div>
                    </div>
                    <div class="form-item">
                        <!-- <label>验证码</label> -->
                        <div class="p-r">
                            <input id="validateCode" v-model="smsCode" class="input verification-code" type="text" placeholder="请输入验证码" maxlength="6" />
                            <p class="send-code">
                                <input type="button" @click="sentCodeBing" :value="msgText" id="send-code" />
                            </p>
                        </div>
                    </div>
                    <p class="error-text" id="error-code" style="display: none">
                        <svg class="iconpark-icon-error">
                            <use href="#Frame"></use>
                        </svg>
                    </p>
                    <div style="height: 26px"></div>
                    <button class="login" id="loginBtn2" @click="bindingLogin" @keydown.enter="bindingLogin" v-loading='loading' :disabled="loading">
                        立即绑定
                    </button>
                    <!-- <p class="form-desc">绑定后，您用该手机号也可登录该账号</p> -->
                </div>
                <div class="agreement-policy">
                    <p>
                        登录即表示同意<span class="link" @click="goPolicy(1)">用户协议</span>和<span class="link" @click="goPolicy(2)">个人信息保护政策</span>
                    </p>
                </div>
            </div>
            <!-- 收集信息 -->
            <div class="forget-password-wrapper" v-show="tabIndex == 7">
                <div class="msg_div">
                    <div class="right_jump" @click="clickjump">跳过</div>
                    <div class="title_msg">完善信息</div>
                    <div class="tit_tip">为您提供个性化解决方案和教程推荐服务</div>
                    <div class="f0_tit_div">您的行业： </div>
                    <el-radio-group v-model="industry">
                        <el-radio v-for="(item,index ) in industryData" :key="index" :label="item" size="medium" border>{{item}}</el-radio>
                    </el-radio-group>
                    <div class="f0_tit_div">从哪里了解到我们？ </div>
                    <el-radio-group v-model="way">
                        <el-radio v-for="(item,index ) in wayData" :key="index" :label="item" size="medium" border>{{item}}</el-radio>
                    </el-radio-group>
                    <button style="margin-top: 30px;" v-if="!industry||!way" class="login no_use" @click="clickUse" @keydown.enter="clickUse" v-loading='loading' :disabled="loading">立即使用 </button>
                    <button style="margin-top: 30px;" v-else class="login" @click="clickUse" @keydown.enter="clickUse" v-loading='loading' :disabled="loading">立即使用 </button>
                </div>
            </div>

            <!-- 失败弹窗 -->
            <!-- width="route.query.source_login?'90%':500" -->
            <el-dialog title="绑定失败" :visible.sync="bingErrorLog" append-to-body @close="handleCloseError" :width='dialogWidth' class="bindDialog">
                <div class="dia_tit">您输入的手机号「{{mobile}}」已被其他账号绑定，您可以将该手机号从被绑定的账号中解除绑定后再绑定至当前账号中，或将原账号注销。</div>
                <div class="dia_p">
                    温馨提示：解除绑定或账号注销后，您将无法通过手机号登录原账号。
                </div>
                <el-button type="primary" @click="handleCloseError">我知道了</el-button>
                <!-- <button class="login" @click="bindingLogin" @keydown.enter="bindingLogin">
                    我知道了
                </button> -->

                <el-tooltip popper-class="topBar-user-popper" placement="top" effect="light">
                    <div slot="content">
                        <div class="contact-box">
                            <img src="@/assets/images/weChatCode.jpg" class="xImg contact-qr" />
                            <p class="contact-desc">扫码添加客服微信</p>
                            <p class="online-time flex center">在线时间：每日09:00 ~ 18:00</p>
                        </div>
                    </div>
                    <div class="dia_que">
                        遇到问题？联系客服
                    </div>
                </el-tooltip>
            </el-dialog>
            <!-- <el-dialog width="30%" title="内层 Dialog" :visible.sync="bingErrorLog"  append-to-body >
            </el-dialog> -->
            <!-- 忘记密码 -->
            <div class="forget-password-wrapper" v-show="tabIndex == 2">
                <div v-if="language=='zh_CN'">
                    <div>
                        <span class="back-login" id="back-login" @click="tabIndex = 0">
                            <svg class="iconpark-icon">
                                <use href="#c-left"></use>
                            </svg>
                            返回登录
                        </span>
                    </div>
                    <ul class="tab">
                        <li class="active">找回密码</li>
                    </ul>
                    <div class="form-wrapper" id="retrieve-password">
                        <div class="form-item">
                            <label>手机号/邮箱</label>
                            <div>
                                <input v-model="accountNumber" id="tel3" class="input" placeholder="请输入手机号/邮箱" />
                            </div>
                        </div>
                        <div class="form-item">
                            <label>验证码</label>
                            <div class="p-r">
                                <input v-model="forgetCode" class="input verification-code" type="text" id="validateCode2" placeholder="请输入验证码" maxlength="6" />
                                <p class="send-code">
                                    <input type="button" @click="forgetSentCode" :value="forgetMsgText" />
                                </p>
                            </div>
                        </div>
                        <div class="form-item">
                            <label>设置新密码</label>
                            <div>
                                <input class="input" type="password" id="resetPassword" v-model="resetPassword" maxlength="15" show-password placeholder="请输入6-15位新密码" />
                            </div>
                        </div>
                        <p class="error-text" id="error-retrieve-password" style="display: none">
                            <svg class="iconpark-icon-error">
                                <use href="#Frame"></use>
                            </svg>
                        </p>
                        <button class="login" id="change-password-btn" @click="forgetPwdLogin">
                            确认登录
                        </button>
                    </div>
                    <div class="agreement-policy" style="margin-top:52px">
                        <p>
                            登录即表示同意<span class="link" @click="goPolicy(1)">用户协议</span>和<span class="link" @click="goPolicy(2)">个人信息保护政策</span>
                        </p>
                    </div>
                </div>
                <div v-else>
                    <!-- 输入邮箱 -->
                    <div v-if="forgetFlag==1">
                        <div class="top_div">
                            <span class="back-login" id="back-login" @click.stop="tabIndex = 1">
                                <svg class="iconpark-icon">
                                    <use href="#c-left"></use>
                                </svg>
                                {{$t(`loginDialogPage.reset.back`)}}
                            </span>
                            <div class="logo-box">
                                <img class="logo" style="margin-top:10px" src="@/assets/images/logo.png" />
                            </div>
                        </div>
                        <div class="content_div" style="height:412px">
                            <ul class="tab">
                                <li style="margin-top:10px;font-weight:500;font-size:20px"> {{$t(`loginDialogPage.reset.title`)}}</li>
                            </ul>
                            <p class="tit_cont">{{$t(`loginDialogPage.reset.tit_cont`)}}</p>
                            <div class="form-wrapper" id="retrieve-password" style="margin-top:62px">
                                <div class="form-item">
                                    <label> {{$t(`loginDialogPage.reset.email_label`)}}</label>
                                    <div>
                                        <input v-model="accountNumber" id="tel3" class="input" :placeholder="$t(`loginDialogPage.reset.email_label_placeholder`)" />
                                    </div>
                                </div>
                                <p class="error-text" id="error-retrieve-password" style="display: none">
                                    <svg class="iconpark-icon-error">
                                        <use href="#Frame"></use>
                                    </svg>
                                </p>
                                <button class="login" id="change-password-btn" @click="forgetSendEmail">
                                    {{$t(`loginDialogPage.reset.btn`)}}
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- 输入验证码 -->
                    <div v-if="forgetFlag==2">
                        <div class="top_div">
                            <span class="back-login" id="back-login" @click.stop="forgetFlag = 1">
                                <svg class="iconpark-icon">
                                    <use href="#c-left"></use>
                                </svg>
                                {{$t(`loginDialogPage.reset.back`)}}
                            </span>
                            <div class="logo-box">
                                <img class="logo" style="margin-top:10px" src="@/assets/images/logo.png" />
                            </div>
                        </div>
                        <div class="content_div" style="height:412px">
                            <ul class="tab">
                                <li style="margin-top:10px;font-weight:500;font-size:20px"> {{$t(`loginDialogPage.reset.sending`)}}</li>
                            </ul>
                            <p class="tit_cont">{{$t(`loginDialogPage.reset.sent_to`)+'xxx@'+accountNumber.split('@')[1]}}</p>
                            <div class="form-wrapper" id="retrieve-password" style="margin-top:62px">
                                <div class="form-item">
                                    <label> {{$t(`loginDialogPage.reset.code_label`)}}</label>
                                    <div>
                                        <input id="validateCode" v-model="forgetCode" class="input verification-code" type="text" :placeholder="$t(`loginDialogPage.code_placeholder`) " maxlength="6" />

                                    </div>
                                </div>
                                <p class="error-text" id="error-retrieve-password" style="display: none">
                                    <svg class="iconpark-icon-error">
                                        <use href="#Frame"></use>
                                    </svg>
                                </p>
                                <button class="login" id="change-password-btn" @click="forgetSendEmailNext">
                                    {{$t(`loginDialogPage.reset.next`)}}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div v-if="forgetFlag==3">
                        <div class="top_div">
                            <span class="back-login" id="back-login" @click.stop="forgetFlag = 2">
                                <svg class="iconpark-icon">
                                    <use href="#c-left"></use>
                                </svg>
                                {{$t(`loginDialogPage.reset.back`)}}
                            </span>
                            <div class="logo-box">
                                <img class="logo" style="margin-top:10px" src="@/assets/images/logo.png" />
                            </div>
                        </div>
                        <!-- 输入新密码 -->
                        <div class="content_div" style="height:412px">
                            <ul class="tab">
                                <li style="margin-top:10px;font-weight:500;font-size:20px"> {{$t(`loginDialogPage.reset.tit_reset`)}}</li>
                            </ul>

                            <div class="form-wrapper" id="retrieve-password" style="margin-top:62px">
                                <div class="form-item">
                                    <label>{{$t(`loginDialogPage.reset.newpsd`)}}</label>
                                    <div class="p-r login-password">
                                        <input class="input" id="pwd" maxlength="15" :type="pwdPreview ? 'password' : 'text'" v-model="resetPassword" :placeholder="$t(`loginDialogPage.psd_placeholder`)" />
                                        <svg class="iconpark-icon" v-if="pwdPreview" @click="pwdPreview = false" id="preview-closed">
                                            <use href="#preview-close"></use>
                                        </svg>
                                        <svg v-else class="iconpark-icon" @click="pwdPreview = true" id="preview-opend">
                                            <use href="#preview-open"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div class="form-item">
                                    <label>{{$t(`loginDialogPage.reset.configpsd`)}}</label>
                                    <div class="p-r login-password">
                                        <input class="input" id="pwd" maxlength="15" :type="pwdPreview ? 'password' : 'text'" v-model="configNewPassword" :placeholder="$t(`loginDialogPage.psd_placeholder`)" />
                                        <svg class="iconpark-icon" v-if="pwdPreview" @click="pwdPreview = false" id="preview-closed">
                                            <use href="#preview-close"></use>
                                        </svg>
                                        <svg v-else class="iconpark-icon" @click="pwdPreview = true" id="preview-opend">
                                            <use href="#preview-open"></use>
                                        </svg>
                                    </div>
                                </div>
                                <p class="error-text" id="error-retrieve-password" style="display: none">
                                    <svg class="iconpark-icon-error">
                                        <use href="#Frame"></use>
                                    </svg>
                                </p>
                                <button class="login" id="change-password-btn" @click="resetPsd">
                                    {{$t(`loginDialogPage.login`)}}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="agreement-policy">
                        <p>
                            {{$t(`loginDialogPage.confirmation_agreement.text1`)}}<span class="link" @click="goPolicy(1)"> {{$t(`loginDialogPage.confirmation_agreement.agreement1`)}}</span> {{$t(`loginDialogPage.confirmation_agreement.text2`)}}<span class="link" @click="goPolicy(2)">{{$t(`loginDialogPage.confirmation_agreement.agreement2`)}}</span>
                        </p>
                    </div>
                </div>
            </div>

            <!-- 申请试用 -->
            <div class="forget-password-wrapper" v-show="tabIndex == 3">
                <div class="logo-box">
                    <img class="logo" src="@/assets/images/logo.png" />
                    <svg class="iconpark-icon-close" id="iconpark-icon-close" @click="$store.commit('setLoginDisplay', false)">
                        <use href="#c-close"></use>
                    </svg>
                </div>
                <div style="text-align: center;">
                    <h5 style="font-size:20px;font-weight: 1000;color: rgb(51, 51, 51);padding-top: 20px;">免费试用</h5>
                    <div>留下你的联系方式，立享产品试用及服务</div>
                </div>
                <div class="form-wrapper" id="retrieve-password">
                    <div class="form-item">
                        <div>
                            <input v-model="name" id="tel3" class="input" placeholder="称呼" />
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="p-r">
                            <input v-model="mobile" class="input" placeholder="联系方式" />
                            <div style="font-size: 13px;color: #f03737;" v-show="!isValidMobile">手机号码格式不正确</div>
                        </div>
                    </div>
                    <div class="form-item">
                        <div>
                            <input v-model="company_name" class="input" placeholder="企业名称" />
                        </div>
                    </div>
                    <div class="form-item">
                        <div>
                            <input v-model="email" class="input" placeholder="邮箱" />
                            <div style="font-size: 13px;color: #f03737;" v-show="!isValidEmail">邮箱格式不正确</div>
                        </div>
                    </div>
                    <p class="error-text" id="error-retrieve-password" style="display: none">
                        <svg class="iconpark-icon-error">
                            <use href="#Frame"></use>
                        </svg>
                    </p>
                    <button class="login" @click="submitOnTrial">
                        提交
                    </button>
                    <p class="form-desc">
                        已有账号
                        <span @click="tabIndex = 0" class="color-primary cursor-pointer" id="register-now">
                            立即登录
                        </span>
                    </p>
                </div>
            </div>

            <!-- 设置新密码成功 -->
            <div class="set-password-success-wrapper" style="display: none">
                <div>
                    <svg class="iconpark-icon-success">
                        <use href="#c-success"></use>
                    </svg>
                    <p class="success-tips">设置新密码成功</p>
                    <p class="success-desc"><span id="countdown"></span>s 后进入元空AI</p>
                </div>
                <button class="login" onclick="location.href='betawww.chatexcel.com'">
                    开始使用
                </button>
            </div>
        </div>
    </div>
</template>
<script>


import { userSendMsg, userLogin, resetPassWord, applyForProbation, emailSendMsg, getWechatQr, scanCodeLogin, getUserInfo, bindingAccount, loginSendCode, mealActivationState, activationMeal, getAccessToken, supplementaryState, saveSupplementary, ZW_bindingAccount } from "@/api/user.js";
import { getCookie, setCookie } from "@/utils/cookie";
import { checkModbile, validateEmail } from '@/utils/utils'

import i18n from '../../i18n/index'

import { domainName } from '@/utils/domainName'

let MSGINIT = i18n.t('loginDialogPage.send_code')
let MSGSCUCCESS = "${time}" + i18n.t('loginDialogPage.repeat')
let FORGETMSGSCUCCESS = "${time}" + i18n.t('loginDialogPage.repeat')
const MSGTIME = 30;
export default {
    name: "loginDIalog",
    data() {
        return {
            language: this.$store.state.language,//多语言
            loading: false,
            status: false,
            resultDialogVisible: false,
            tabIndex: this.$store.state.language == 'zh_CN' ? 5 : 1,
            mobile: "",
            pwdPreview: true,
            accountNumber: "",
            password: "",
            msgText: MSGINIT,
            msgKey: false,
            msgTime: 30,
            forgetMsgText: MSGINIT,
            forgetMsgKey: false,
            forgetMsgTime: 30,
            resetPassword: "",
            smsCode: "",
            forgetMobile: "",
            forgetCode: "",
            name: '',
            company_name: '',
            email: '',
            isValidMobile: true,
            isValidEmail: true,
            loginDisplay: this.$store.state.loginDisplay,//
            getchatDetail: {},
            isMaskVisible: false,
            timeoutId: null,
            bingErrorLog: false,//绑定失败弹窗
            loadingLog: false,
            dialogWidth: null,
            source: '',//来源
            forgetFlag: 1,//忘记密码步骤
            configNewPassword: '',
            environment: '',//当前环境
            industry: '',
            way: '',
            industryData: ['教育培训', '医疗健康', '财务金融', '政府单位', '电商', '互联网', '广告传媒', '人力资源', '制造业', '餐饮酒店', '农业', '其他'],
            wayData: ['小红书', '抖音', '视频号', '百度', '公众号', '朋友推荐', '其他']
        };
    },
    computed: {
        setLoginDisplay() {
            return this.$store.state.loginDisplay
        },
    },
    watch: {
        setLoginDisplay: function (data) {
            console.log(this.$store.state.language)
            this.loginDisplay = data
            this.init()
            clearTimeout(this.timeoutId);
            console.log(data, 3333, this.timeoutId, this.loginDisplay)
            if ((this.loginDisplay || window.jsbridge) && this.language == 'zh_CN') {
                if (getCookie("login_token")) {
                    this.getUserInfoFun()
                } else {
                    this.getchatImg()
                    // this.getUserInfoFun()
                }
            }
            if (this.tabIndex == 6) {
                this.tabIndex = 5
            }
        }
    },
    props: {
        display: {
            type: Boolean,
            default: true,
        },
    },
    created() {

        //根据不同路由跳转不同页面
        if (this.isMobile()) {
            // console.log("手机端")
            if (this.$route.query.iframes || this.$route.query.source_login ) {
                return
            }
            window.location.replace(domainName.api10 + '/#/homePageView');
            return
        } else {
            // console.log("PC端")
        }
        // console.log(i18n, this.$route, 45678910)
        MSGINIT = i18n.t('loginDialogPage.send_code')
        MSGSCUCCESS = "${time}" + i18n.t('loginDialogPage.repeat')
        FORGETMSGSCUCCESS = "${time}" + i18n.t('loginDialogPage.repeat')
        this.$forceUpdate()
        if (this.$route.query.login_token) {
            this.$store.commit("setloginToken", this.$route.query.login_token);
        }

        this.timeoutId = null;
        // 测试环境修改为内部验证码登录
        this.environment = location.origin == 'https://betawww.chatexcel.com' ? 'test' : location.origin == 'http://localhost:8068' ? 'test' : 'production'
        // console.log(this.environment, 999)
    },
    beforeDestroy() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    },
    mounted() {
        console.log(this.language)
        if (getCookie("login_token") && getCookie("login_token") != 'null' && getCookie("login_token") != null) {
            this.getUserInfoFun()
        } else {
            if ((this.loginDisplay && this.tabIndex == 5) || window.jsbridge || this.$route.query.source_login == 'true') { //官网登录//联想登录//其他合作商从工作台登录嵌套
                this.getchatImg()
            }
        }


        if (window.jsbridge) {
            this.dialogWidth = '400px'
            this.source = 'lenovo'
        } else if (this.$route.query.source_login) {
            this.dialogWidth = '600px'
        } else {
            this.dialogWidth = '600px'
        }
        // 来源判断
        if (window.jsbridge) {
            this.source = 'lenovo'
        } else if (this.$route.query.activation_id) {
            this.source = 'zhihu'
        } else {
            this.source = 'web'
        }
    },
    methods: {
        //该方法用于判断是否进入手机端
        isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) || window.innerWidth <= 1024
            return flag
        },
        getUserInfoFun() {
            if (getCookie("login_token")) {
                console.log(getCookie("access_token"), '88888')
                getUserInfo({
                    access_token: getCookie("access_token"),
                }).then((res) => {
                    this.$store.commit("setSource", res.data.data.source);
                    if (!res.data.data.mobile && this.language == 'zh_CN') {
                        this.tabIndex = 6
                        clearTimeout(this.timeoutId);
                    }
                });
            }
        },
        init() {
            if (!this.loginDisplay) {
                this.smsCode = ''
            }
            clearTimeout(this.timeoutId);
            // 
        },
        // 领取消息提醒接口
        showMeg() {
            mealActivationState({
                access_token: getCookie("access_token"),
                activation_id: this.$route.query.activation_id,
            }).then((res) => {
                if (res.data.data.state) {
                    if (res.data.data.status == 1) {
                        this.$message({
                            showClose: true,
                            type: 'success',
                            message: res.data.data.msg,
                            center: true
                        });
                    } else {
                        this.$message({
                            showClose: true,
                            type: 'error',
                            message: res.data.data.msg,
                            center: true
                        });
                    }

                }

            });
        },
        getchatImg() {
            this.loadingLog = true
            getWechatQr({
                access_token: getCookie("access_token"),
            }).then((res) => {
                this.getchatDetail = res.data.data
                this.loadingLog = false
                this.getChatState()
            });
        },
        getChatState() {
            this.timeoutId = setTimeout(() => {
                scanCodeLogin({
                    access_token: getCookie("access_token"),
                    scene_str: this.getchatDetail.scene_str,
                    activation_id: this.$route.query.activation_id,
                    source: this.source
                }).then((res) => {
                    clearTimeout(this.timeoutId);
                    if (this.tabIndex == 6) {
                        clearTimeout(this.timeoutId);
                        return
                    }
                    if (res.data.data.is_login == 'false') {
                        this.getChatState()
                    } else {
                        this.$message.success("扫码登录成功！");

                        activationMeal({
                            access_token: getCookie("access_token"),
                            activation_id: this.$route.query.activation_id,
                        }).then((res) => {
                            this.showMeg()
                        });
                        this.$store.commit("setloginToken", res.data.data.login_token);
                        let loginDatas = res.data.data
                        getUserInfo({
                            access_token: getCookie("access_token"),
                        }).then((res) => {
                            this.$store.commit("setSource", res.data.data.source);
                            setCookie('userInfo', JSON.stringify(res.data.data))
                            localStorage.setItem('login_token', getCookie("login_token"))
                            localStorage.setItem('access_token', getCookie("access_token"))
                            if (loginDatas.binding_pop_up == 'true') {
                                this.tabIndex = 6
                                window.parent.postMessage("lxSuccessNoMobile", "*");
                            } else {
                                this.$store.commit("setMobile", res.data.data.mobile);
                                this.supplementaryStateFun()
                                // window.parent.postMessage("success", "*");
                                // this.$store.commit("setLoginDisplay", false);
                            }
                        });
                    }
                });
            }, 2000);
        },
        showMask() {
            this.isMaskVisible = true;
        },
        hideMask() {
            this.isMaskVisible = false;
        },
        refreshQrcode() {
            // 这里可以添加刷新二维码的逻辑
            console.log('刷新二维码');
            // 例如，重新生成二维码图片
            this.getchatImg()
        },
        debouncedShowMask() {
            this.showMask();
            this.$nextTick(() => this.$forceUpdate());


        },
        // 切换登录方式
        checkPlan() {
            this.tabIndex = 1
            clearTimeout(this.timeoutId);
            this.$nextTick(() => this.$forceUpdate());
        },
        checkWXLogin() {
            this.tabIndex = 5
            this.getchatImg()
        },
        handleClose() {
            this.resultDialogVisible = false
        },
        submitOnTrial() {
            this.isValidMobile = checkModbile(this.mobile)
            if (!this.isValidMobile) return
            this.isValidEmail = validateEmail(this.email)
            if (!this.isValidEmail) return

            applyForProbation({
                access_token: getCookie('access_token'),
                name: this.name,
                mobile: this.mobile,
                company_name: this.company_name,
                email: this.email,
            }).then(res => {
                this.resultDialogVisible = true
                this.status = true
                this.$store.commit("setLoginDisplay", false);
                this.$store.commit("setloginToken", res.data.data.login_token);
                this.$store.commit("setMobile", this.mobile);
            })
        },
        // 点击忘记密码
        clickForget() {
            this.tabIndex = 2;
            this.accountNumber = '';
            this.forgetCode = '';
            this.resetPassword = '';
            this.configNewPassword = ''
        },
        // 中文 忘记密码
        forgetPwdLogin() {
            resetPassWord({
                account_number: this.accountNumber,
                code: this.forgetCode,
                password: this.resetPassword,
                access_token: getCookie("access_token"),
            }).then((res) => {
                this.tabIndex = 2
                this.password = this.resetPassword
                this.pwdLogin()
                console.log(res);
            });
        },
        registerNow() {
            this.tabIndex = 3;
        },
        emailLogin() {
            this.loading = true
            this.$nextTick(() => {
                userLogin({
                    account_number: this.email,
                    code: this.smsCode,
                    access_token: getCookie("access_token"),
                    activation_id: this.$route.query.activation_id,
                    source: this.source
                }).then((res) => {
                    this.$store.commit("setLoginDisplay", false);
                    this.$store.commit("setloginToken", res.data.data.login_token);
                    this.$store.commit("setMobile", this.email);
                    this.$message.success("登录成功！");
                    activationMeal({
                        access_token: getCookie("access_token"),
                        activation_id: this.$route.query.activation_id,
                    }).then((res) => {
                        this.showMeg()
                    });
                    this.supplementaryStateFun()
                    // window.parent.postMessage("success", "*");
                    this.loading = false
                }).catch(err => {
                    // this.resultDialogVisible = true
                    window.parent.postMessage("error", "*");
                    this.$store.commit("setLoginDisplay", false);
                    this.status = false
                    this.loading = false
                })
            })

        },
        // 手机号/邮箱登录
        codeLogin() {
            this.loading = true
            this.$nextTick(() => {
                userLogin({
                    account_number: this.mobile,
                    code: this.smsCode,
                    access_token: getCookie("access_token"),
                    activation_id: this.$route.query.activation_id,
                    source: this.source
                }).then((res) => {
                    // this.$store.commit("setLoginDisplay", false);
                    this.$store.commit("setloginToken", res.data.data.login_token);
                    this.$store.commit("setMobile", this.mobile);
                    this.$message.success("登录成功！");
                    activationMeal({
                        access_token: getCookie("access_token"),
                        activation_id: this.$route.query.activation_id,
                    }).then((res) => {
                        this.showMeg()
                    });
                    this.supplementaryStateFun()
                    // window.parent.postMessage("success", "*");
                    this.loading = false
                }).catch(err => {
                    // this.resultDialogVisible = true
                    window.parent.postMessage("error", "*");
                    // this.$store.commit("setLoginDisplay", false);
                    this.status = false
                    this.loading = false
                })
            })

        },
        // 密码登录
        pwdLogin() {
            this.loading = true
            this.$nextTick(() => {
                userLogin({
                    account_number: this.accountNumber,
                    password: this.password,
                    access_token: getCookie("access_token"),
                    activation_id: this.$route.query.activation_id,
                    source: this.source
                }).then((res) => {
                    console.log(res, 6666)
                    // this.$store.commit("setLoginDisplay", false);
                    this.$store.commit("setloginToken", res.data.data.login_token);
                    this.$store.commit("setMobile", this.accountNumber);
                    this.$message.success("登录成功！");
                    activationMeal({
                        access_token: getCookie("access_token"),
                        activation_id: this.$route.query.activation_id,
                    }).then((res) => {
                        this.showMeg()
                    });
                    this.supplementaryStateFun()
                    // window.parent.postMessage("success", "*");
                    this.loading = false
                }).catch(err => {
                    // this.resultDialogVisible = true
                    window.parent.postMessage("error", "*");
                    // this.$store.commit("setLoginDisplay", false);
                    this.status = false
                    this.loading = false
                })
            })

        },
        // 绑定手机号
        bindingLogin() {
            this.loading = true
            this.$nextTick(() => {
                // 知网接口不同
                if (JSON.parse(getCookie('howNet'))) {
                    ZW_bindingAccount({
                        account_number: this.mobile,
                        code: this.smsCode,
                        access_token: getCookie("access_token"),
                    }).then((res) => {
                        if (res.data.code == 0) {
                            this.$message.success("绑定成功！");
                            getUserInfo({
                                access_token: getCookie("access_token"),
                            }).then((res) => {
                                this.$store.commit("setMobile", res.data.data.mobile);
                                this.supplementaryStateFun()
                                // window.parent.postMessage("success", "*");

                            });
                            // this.$store.commit("setLoginDisplay", false);
                            this.$store.commit("setloginToken", res.data.data.login_token);
                            this.$store.commit("setMobile", this.mobile);
                            this.loading = false
                        }
                    }).catch(err => {
                        console.log(err, 34567898765)
                        // this.resultDialogVisible = true
                        window.parent.postMessage("error", "*");
                        // this.$store.commit("setLoginDisplay", false);
                        this.status = false
                        this.loading = false
                    })
                    return
                }
                bindingAccount({
                    account_number: this.mobile,
                    code: this.smsCode,
                    access_token: getCookie("access_token"),
                }).then((res) => {
                    if (res.data.code == 0) {
                        this.$message.success("绑定成功！");
                        getUserInfo({
                            access_token: getCookie("access_token"),
                        }).then((res) => {
                            this.$store.commit("setMobile", res.data.data.mobile);
                            this.supplementaryStateFun()
                            // window.parent.postMessage("success", "*");

                        });
                        // this.$store.commit("setLoginDisplay", false);
                        this.$store.commit("setloginToken", res.data.data.login_token);
                        this.$store.commit("setMobile", this.mobile);
                        this.loading = false
                    }
                }).catch(err => {
                    console.log(err, 34567898765)
                    // this.resultDialogVisible = true
                    window.parent.postMessage("error", "*");
                    // this.$store.commit("setLoginDisplay", false);
                    this.status = false
                    this.loading = false
                })
            })

        },
        // 手机号/邮箱 
        sentCode() {
            if (this.msgKey) return;
            this.timeCacl();
            this.$nextTick(() => {
                loginSendCode({
                    account_number: this.mobile,
                    access_token: getCookie("access_token"),
                    business_type: ''
                }).then((res) => {
                    // 发送验证码
                });
            })
        },
        emailsentCode() {
            if (this.msgKey) return;
            this.timeCacl();
            this.$nextTick(() => {
                emailSendMsg({
                    email: this.email,
                    access_token: getCookie("access_token"),
                }).then((res) => {
                    // 发送验证码
                });
            })
        },
        // 绑定手机号发送验证码
        sentCodeBing() {

            if (this.msgKey) return;
            this.timeCacl();
            this.$nextTick(() => {
                loginSendCode({
                    account_number: this.mobile,
                    access_token: getCookie("access_token"),
                    business_type: JSON.parse(getCookie('howNet')) ? 'zhiwang_binding_account_number' : 'wechat_binding_account_number'
                }).then((res) => {
                    if (res.data.code == 10031) {
                        this.bingErrorLog = true
                    }
                    // 发送验证码
                });
            })
        },
        // 点击跳转谷歌登录
        goolgeLogin() {
            console.log(process.env.NODE_ENV, location.origin);
            let env = location.origin == 'https://betawww.chatexcel.com' ? 'test' : location.origin == 'http://localhost:8068' ? 'test' : 'production'
            location.href = 'https://authorization.chatexcel.com/authorization/google/' + getCookie("access_token") + '/' + env
        },
        // 忘记密码发送邮箱---海外版
        forgetSendEmail() {
            if (this.msgKey) return;
            this.timeCacl();
            this.$nextTick(() => {
                loginSendCode({
                    account_number: this.accountNumber,
                    access_token: getCookie("access_token"),
                    business_type: 'reset_passwords'
                }).then((res) => {
                    this.forgetFlag = 2
                    // 发送验证码
                });
            })
        },
        // 忘记密码输入邮箱下一步---海外版
        forgetSendEmailNext() {
            this.forgetFlag = 3
        },
        // 重置密码---海外版
        resetPsd() {
            if (this.resetPassword != this.configNewPassword) {
                this.$message.error("Two passwords are inconsistent！");
                return
            }
            resetPassWord({
                account_number: this.accountNumber,
                code: this.forgetCode,
                password: this.resetPassword,
                access_token: getCookie("access_token"),
            }).then((res) => {
                this.forgetFlag = 1
                this.tabIndex = 2
                this.password = this.resetPassword
                this.pwdLogin()
                console.log(res);
            });
        },
        // 关闭绑定失败提示弹窗
        handleCloseError() {
            this.bingErrorLog = false

        },

        timeCacl() {
            // 计时避免重复发送
            this.msgText = MSGSCUCCESS.replace("${time}", this.msgTime);
            this.msgKey = true;
            const time = setInterval(() => {
                this.msgTime--;
                this.msgText = MSGSCUCCESS.replace("${time}", this.msgTime);
                if (this.msgTime === 0) {
                    this.msgTime = MSGTIME;
                    this.msgText = MSGINIT;
                    this.msgKey = false;
                    clearInterval(time);
                }
            }, 1000);
        },
        // 忘记密码发送验证码
        forgetSentCode() {
            if (this.forgetMsgKey) return;
            this.forgetTimeCacl();

            loginSendCode({
                account_number: this.accountNumber,
                access_token: getCookie("access_token"),
                business_type: 'reset_passwords'
            }).then((res) => {
                // 发送验证码
            });
        },
        forgetTimeCacl() {
            // 计时避免重复发送
            this.forgetMsgText = FORGETMSGSCUCCESS.replace(
                "${time}",
                this.forgetMsgTime
            );
            this.forgetMsgKey = true;
            const time = setInterval(() => {
                this.forgetMsgTime--;
                this.forgetMsgText = FORGETMSGSCUCCESS.replace(
                    "${time}",
                    this.forgetMsgTime
                );
                if (this.forgetMsgTime === 0) {
                    this.forgetMsgTime = MSGTIME;
                    this.forgetMsgText = MSGINIT;
                    this.forgetMsgKey = false;
                    clearInterval(time);
                }
            }, 1000);
        },
        goPolicy(type) {
            if (type == 1) {
                // parent.location.href = location.origin + '/?#/UserAgreement'
                // location.reload()
                // return
                // this.$store.commit("setLoginDisplay", false);
                if (window.jsbridge) {
                    this.$router.push()
                    jsbridge.getLaunchOptions().then(res => {
                        parent.location.href = location.origin + '/?#/UserAgreement'
                        location.reload()
                    })
                } else {
                    window.open(location.origin + '/?#/UserAgreement')
                }
            } else if (type == 2) {
                // parent.location.href = location.origin + '/?#/PrivacyAgreement'
                if (window.jsbridge) {
                    this.$router.push()
                    jsbridge.getLaunchOptions().then(res => {
                        parent.location.href = location.origin + '/?#/PrivacyAgreement'
                        location.reload()
                    })
                } else {
                    window.open(location.origin + '/?#/PrivacyAgreement')
                }
            }

        },
        // 跳过
        clickjump() {
            this.$store.commit("setLoginDisplay", false);
            window.parent.postMessage("success", "*");
            location.href = domainName.api8 + '/#/proSheet'
        },
        // 获取完善用户信息弹窗状态
        supplementaryStateFun() {
            if (window.jsbridge) {
                this.$store.commit("setLoginDisplay", false);
                window.parent.postMessage("success", "*");
                return
            }
            supplementaryState({
                access_token: getCookie("access_token"),
            }).then((res) => {
                if (res.data.data.popup_state) {
                    this.tabIndex = 7
                } else {
                    this.$store.commit("setLoginDisplay", false);
                    window.parent.postMessage("success", "*");
                    location.href = domainName.api8 + '/#/proSheet'

                }
            });
        },
        // 立即使用
        clickUse() {
            if (!this.industry || !this.way) {
                this.$message.error("请完善信息后再点击提交！");
                return
            }
            this.loading = true
            this.$nextTick(() => {
                saveSupplementary({
                    industry: this.industry,
                    know_the_source: this.way,
                    source: this.source,
                    access_token: getCookie("access_token"),
                }).then((res) => {
                    this.$message.success("信息完善成功！");
                    this.$store.commit("setLoginDisplay", false);
                    window.parent.postMessage("success", "*");
                    this.loading = false
                }).catch(err => {
                    this.loading = false
                })
            })
        },
        // // 关闭登录弹窗
        // closeDialog() {
        //     this.$store.commit("setLoginDisplay", false);
        // },
    },
};
</script>

<style scoped>
body {
  background: none !important;
}
.msg_div {
  padding: 20px;
  box-sizing: border-box;
}
.msg_div .right_jump {
  text-align: right;
  cursor: pointer;
}
.msg_div .title_msg {
  margin-top: 12px;
  color: #000;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}
.msg_div .tit_tip {
  text-align: center;
}
.msg_div .f0_tit_div {
  margin-top: 16px;
  color: #000;
}
.msg_div ::v-deep .el-radio-group {
  margin-top: 10px;
}
.msg_div ::v-deep .el-radio {
  font-weight: normal;
}
.msg_div ::v-deep .el-radio.is-bordered {
  margin-right: 6px;
  margin-left: 0 !important;
  width: 95px;
  margin-bottom: 10px;
  text-align: center;
}
.msg_div ::v-deep .el-radio__input {
  display: none;
}
.msg_div ::v-deep .el-radio.is-bordered.is-checked {
  background-color: #35ad84;
  border-color: #35ad84;
  color: #fff !important;
}
.msg_div ::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #fff !important;
}
.no_use {
  background: #fff !important;
  color: #35ad84 !important;
  border: solid 1px #35ad84 !important;
}
.flex_div {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 30px;
  background: none;
}
.login-dialog .agreement-policy {
  padding-bottom: -10px;
  padding: 0 10px !important;
}
.google_login {
  width: 340px;
  margin: 12px auto 0px;
}
.google_login button {
  display: block;
  color: #000;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: #fff;
  border: solid 2px #999;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  /* display: flex;
  text-align: center;
  vertical-align: middle;
  align-items: center; */
}
.google_login img {
  width: 24px;
  position: relative;
  top: 6px;
  margin-right: 10px;
}
.el-divider--horizontal {
  margin: 24px 0 0;
}
.tit_cont {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  line-height: 16px;
}
.agreement-policy .link {
  cursor: pointer;
}
.login-dialog {
  width: 444px !important;
  height: 560px !important;
  margin: 0 !important;
  background: none;
  border-radius: 30px;

  /* height: 600px; */
}
.loginResultDialog {
  .el-dialog__header {
    padding: 0;
  }

  .el-dialog {
    border-radius: 25px;
    overflow: hidden;
  }
}
.title_top {
  text-align: center;
  width: 444px;
  margin: 0 auto;
  margin-top: 30px;
}
.title_top .top_div {
  align-items: center;
  vertical-align: middle;
}
.title_top img {
  width: 40px;
  height: 40px;
  /* margin: 0 auto; */
}
.title_top .top_div h4 {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  align-items: center;
  top: -15px;
  font-size: 18px;
  font-weight: 500;
  color: #000;
  margin-left: 10px;
}
.title_top .login_success {
  text-align: left;
  margin-left: 50px;
  display: flex;
  align-items: center;
}
.title_top .login_success h4 {
  margin-left: 10px;
  font-size: 18px;
  font-weight: 500;
}
.title_top .cont_p {
  text-align: left;
  width: 340px;
  margin: 0 auto;
  margin-top: 10px;
}
.code_img {
  width: 230px;
}
.wx_login {
  text-align: center;
  position: relative;
  width: auto !important;
}
.mask {
  position: absolute;
  top: 0;
  left: 0%;
  width: 230px;
  height: 62%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.mask button {
  padding: 10px 20px;
  /* background: #007bff; */
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.mask button:hover {
  /* background: #0056b3; */
}
.wx_chat {
  width: 40px;
  height: 40px;
  margin: 0 auto;
  display: block;
  margin-top: 16px;
  cursor: pointer;
}
.bindDialog ::v-deep .el-dialog {
  border-radius: 10px;
  /* width: 90%; */
}
.bindDialog ::v-deep .el-dialog__body {
  padding-top: 0;
}
.bindDialog .dia_tit {
  font-size: 13px;
}
.bindDialog .dia_p {
  margin-top: 10px;
  color: red;
}
.bindDialog button {
  margin: 0 auto;
  display: block;
  margin-top: 20px;
}
.bindDialog .dia_que {
  margin: 0 auto;
  display: block;
  text-align: center;
  margin-top: 10px;
  color: #999;
  cursor: pointer;
}
.contact-box {
  text-align: center;
  line-height: 1;
  font-size: 13px;
}

.contact-qr {
  width: 156px;
  height: 156px;
  border-radius: 8px;
}
</style>