import request from '@/utils/axios'
import { getCookie } from "@/utils/cookie";

// import requestFlow from '@/utils/axios-flow'
export function getAccessToken() {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/auth/get-access-token' },
        headers: {
            isToken: false,
        },
        data: {
            appid: '5NnDQmEv94ZLNVA',
            appsecret: "1nrhJKzmK0tsq1SkPBqVqJ8tA4bhVTnQ"
        }
    })
}
export function userSendMsg(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/user/send-msg' },
        data: data
    })
}
export function emailSendMsg(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/user/send-email' },
        data: data
    })
}
export function userLogin(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/user/login' },
        data: data
    })
}
export function resetPassWord(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/user/reset-pass-word' },
        data: data
    })
}
export function applyForProbation(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/user/apply-for-probation' },
        data: data
    })
}
export function meallist(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/meal/list' },
        data: data
    })
}
export function purchasecode(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/meal/get-purchase-qr-code' },
        data: data
    })
}
export function aliCallBack(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/meal/ali-pay-call-back' },
        data: data
    })
}
export function getWechatQr(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/user/get-wechat-login-qr' },
        data: data
    })
}
export function scanCodeLogin(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/user/scan-code-login' },
        data: data
    })
}
export function getUserInfo(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/user/info' },
        data
    })
}
export function bindingAccount(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/user/wx-binding-account-number' },
        data
    })
}
// 知网绑定
export function ZW_bindingAccount(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/user/zw-binding-account-number' },
        data
    })
}
// 绑定手机号--发送验证码
export function loginSendCode(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/user/send-code' },
        data
    })
}
// 退出登录
export function logOut(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/user/log-out' },
        data
    })
}
// 支付成功状态
export function aliPaymentStatus(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/user/ali-payment-status' },
        data
    })
}
export function effectiveMeal(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/user/get-effective-meal' },
        data: data
    })
}
// 领取套餐弹窗
export function mealActivationState(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/user/meal-activation-state' },
        data: data
    })
}
// 领取套餐
export function activationMeal(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/user/activation-meal' },
        data: data
    })
}
// 邀请链接
export function getInvitationUrl(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/user/get-invitation-url' },
        data: data
    })
}
// 邀请记录 
export function getInvitationLog(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/user/get-invitation-log' },
        data: data
    })
}
// 奖励记录
export function getInvitationRewardLog(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/user/get-invitation-reward-log' },
        data: data
    })
}
// 百度推广
export function BDpromotionbrowse(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/user/bd-promotion-browse' },
        data: data
    })
}
// 完善用户信息弹窗状态 
export function supplementaryState(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/user/supplementary-data-state' },
        data: data
    })
}
// 完善用户信息 保存
export function saveSupplementary(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/user/save-supplementary-data' },
        data: data
    })
}
// 兑换
export function convertibility(data) {
    return request({
        url: '/api',
        method: 'post',
        params: { r: 'v1/user/convertibility' },
        data: data
    })
}

export const defaultData = (url, params, method) => {
    // let urls=process.env.NODE_ENV=== 'production' ?url:'/api'+url
    return new Promise((resolve, reject) => {
        if (!params) {
            params = {}
        }
        params.access_token = getCookie("access_token");
        //   let data = qs.stringify(params);
        let data = params
        return request({
            data,
            url: '/api/' + url,
            method: method || "post"
        }).then(res => {
            console.log(res)
            if (res.status == 200) {
                if (res.data.code != 10010 && res.data.code != 1) {
                    resolve(res)
                } else if (res.data.code == 1) {
                    // 接口一些错误信息
                    resolve(res)
                } else {
                    // _this.$notify({
                    //   message: res.data.msg,
                    //   color: '#fff',
                    // });
                }
            } else {
                reject(res)
            }
        }).catch(err => {
            // _this.$notify({
            //   message: '服务器异常，请稍后重试',
            //   color: '#fff',
            // });
            reject(err)
        })
    })

};
// export function getAgent(data) {
//     return requestFlow({
//         url: '/GetAgent',
//         method: 'post',
//         headers: {
//             "Content-Type": "application/json" // 如果写成contentType会报错
//         },
//         data: data
//     })
// }
// export function createAgent(data) {
//     return requestFlow({
//         url: '/CreateAgent',
//         method: 'post',
//         headers: {
//             "Content-Type": "application/json" // 如果写成contentType会报错
//         },
//         data: data
//     })
// }
// export function updateAgent(data) {
//     return requestFlow({
//         url: '/UpdateAgent',
//         method: 'post',
//         headers: {
//             "Content-Type": "application/json" // 如果写成contentType会报错
//         },
//         data: data
//     })
// }