<template>
  <div id="app">
    <svgList />
    <router-view />
    <el-dialog title="" :visible.sync="dialogVisible" @close="handleClose" :show-close="false" width='444px' class="login_dialog">
      <!-- :close-on-click-modal='false' :show-close="false" -->
      <loginDialogPage />
    </el-dialog>
    <!-- <loginDIalog /> -->
  </div>
</template>
<script>
import loginDIalog from "@/components/loginDIalog";
import loginDialogPage from "@/views/loginDialogPage";
import { setCookie, getCookie } from "@/utils/cookie";
import svgList from "@/components/svgList";
import { getUserInfo } from "@/api/user.js";
import { domainName } from "@/utils/domainName";

export default {
  components: {
    loginDIalog,
    svgList,
    loginDialogPage
  },
  data() {
    return {
      loginDisplay: this.$store.state.loginDisplay,//
      dialogVisible: false
    };
  },
  computed: {
    setLoginDisplay() {
      return this.$store.state.loginDisplay
    },
  },
  watch: {
    setLoginDisplay: function (data) {
      this.loginDisplay = data
      this.init()
    }
  },
  mounted() {

    // partner_uuid 渠道id
    // activation_id 激活
    // invitation_id 邀请
    const queryParams = window.location.hash.indexOf('?') == -1 ? {} : this.getQueryParams(); //地址栏参数
    let getparams = getCookie('queryParams') ? JSON.parse(getCookie('queryParams')) : {} //cookie
    console.log(window.location.hash, getparams,queryParams, 111)
    // if(!queryParams.bd_vid){
    //   delete getparams.bd_vid
    //   setCookie('queryParams', JSON.stringify(getparams))
    // }
    if (queryParams.iframes) { //支付弹窗/地址栏有参数iframes    
      getparams.iframes = queryParams.iframes
      setCookie('queryParams', JSON.stringify(getparams))
    } else {
      delete queryParams.iframes
      if (Object.keys(queryParams).length != 0) { //地址栏有参数
        setCookie('queryParams', JSON.stringify(queryParams))
      }
      if (getparams.partner_uuid || getparams.activation_id) { //cookie有
        // return
      }
    }
    //根据不同路由跳转不同页面
    if (this.isMobile()) {
      console.log("手机端")
      if(queryParams.iframes||queryParams.source_login){
        return
      }
      window.location.replace(domainName.api10+'/#/homePageView');
      return
      // this.$router.push({
      //   name: 'mobileEndPage',
      // })
    } else {
      console.log("PC端")
    }
    // console.log(window.location, getCookie("mobile"), 'mobile')
    if (!getCookie("mobile") && getCookie("language") == 'zh_CN'&&!getparams.source_login&&window.location.hash=='#/') {
      // this.$store.commit("setLoginDisplay", true);
    }

    // this.getUserInfoFun()
  },
  methods: {
    init() {
      if (this.loginDisplay) {
        this.dialogVisible = true
      } else {
        this.dialogVisible = false
      }
    },
    getUserInfoFun() {

      // if (getCookie("login_token")) {
      //   getUserInfo({
      //     access_token: getCookie("access_token"),
      //   }).then((res) => {
      //     this.$store.commit("setSource", res.data.data.source); 

      //   });
      // }
    },
    handleClose() {
      this.dialogVisible = false
      this.$store.commit("setLoginDisplay", false);
      if (getCookie("login_token")&&getCookie("mobile")) {
        location.href = domainName.api8 + '/#/proSheet'
      }
    },
    // url 参数
    getQueryParams() {
      const queryStr = window.location.hash.split('?')[1]; // 去掉开头的 "?"
      const params = queryStr.split('&').reduce((acc, item) => {
        const [key, value] = item.split('=');
        acc[decodeURIComponent(key)] = decodeURIComponent(value);
        return acc;
      }, {});

      return params;
    },
    //该方法用于判断是否进入手机端
    isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)||window.innerWidth <= 1024
      return flag
    },
  }
};
</script>
<style scoped>
.login_dialog /deep/ .el-dialog__headerbtn {
  z-index: 100;
}
.login_dialog /deep/ .el-dialog {
  border-radius: 30px;
}
.login_dialog /deep/ .el-dialog__header {
  padding: 0;
}
.login_dialog /deep/ .el-dialog__body {
  padding: 0;
}
</style>
